<script setup lang="ts">
// interface Common {
//   color?: 'green' | 'black' | 'blue' | 'white' | 'yellow'
//   disabled?: boolean
//   size?: 'medium' | 'small'
// }

// interface ButtonProps {
//   type: 'button'
//   onClick: (ev: Event) => void
// }

// interface LinkProps {
//   type: 'link'
//   href: string
//   target?: '_blank'
// }

// type Props = (Common & ButtonProps) | (Common & LinkProps)

type Props = {
	color?: 'green' | 'black' | 'blue' | 'white' | 'yellow' | 'pink'
	disabled?: boolean
	size?: 'medium' | 'small' | 'large' | 'casino' | 'quiz'

	type?: 'button' | 'link' | 'nuxt'
	onClick?: (ev: Event) => void

	href?: string
	to?: string
	target?: '_blank'
}

// TODO: NuxtLink in stead of <a> ? nuxt doenst work. prop to is not working
const props = withDefaults(defineProps<Props>(), {
	color: 'green',
	size: 'medium',
	type: 'button',
})

const tag = computed(() =>
	props.type === 'button'
		? 'button'
		: props.type == 'nuxt'
			? resolveComponent('NuxtLink')
			: 'a',
)
const classes = computed(() => `button--${props.color} button--${props.size}`)
</script>

<template>
	<component
		:is="tag"
		:to="to"
		:href="href"
		:target="target"
		@click="onClick"
		class="button"
		:class="classes"
		:disabled="disabled"
	>
		<slot />
	</component>
</template>

<style scoped>
.button {
	@apply rounded-full leading-none font-semibold text-button inline-flex justify-center items-center select-none relative;

	&:disabled {
		@apply bg-white text-[#656565];

		&:hover {
			@apply bg-white;
		}
	}
}

.button--small {
	@apply px-3 pt-2.5 pb-2;
}

.button--medium {
	@apply px-6 pt-3.5 pb-[13px];
	@apply md:px-6 md:py-3.5 md:pb-[13px];
}

.button--large {
	@apply px-8 md:px-12 py-6 md:py-8 text-[28px];
}

.button--casino {
	@apply px-12 md:px-12 py-7 md:py-7 text-[29px];
}

.button--quiz {
	@apply px-4 pb-2 pt-2.5 text-[14px] sm:px-7 sm:pt-5 sm:pb-4  sm:text-[20px];
}

.button--green {
	@apply bg-nemo-green text-black hover:bg-[#00D137];
}

.button--black {
	@apply bg-black text-white hover:bg-[#222];
}

.button--yellow {
	@apply bg-nemo-yellow hover:bg-[#f1d900];
}

.button--white {
	@apply bg-white hover:bg-[#eee];
}

.button--blue {
	@apply bg-nemo-cyan hover:bg-[#23a7ff];
}

.button--pink {
	@apply bg-nemo-magenta bg-[#dd2e94] text-white;
}
</style>
