import { defineStore } from 'pinia'
import { type NavigationQuery } from '~/generated/operations'
import { NavigationDocument } from './../generated/operations'

export type NavigationNodeMain = Omit<
	Must<NonNullable<NavigationQuery['main']>[0]>,
	'children'
>

export type NavigationNodeSocial = Extract<
	Must<NonNullable<NavigationQuery['social']>[0]>,
	{ __typename: 'socialNavigation_Node' }
>

export type NavigationNodeFooter = Extract<
	Must<NonNullable<NavigationQuery['footer']>[0]>,
	{ __typename: 'footerNavigation_Node' }
>

type NavigationNodeWithChildrenMain = NavigationNodeMain & {
	children: NavigationNodeMain[]
}

export type NavigationNodes = {
	main: NavigationNodeWithChildrenMain[]
	social: NavigationNodeSocial[]
	footer: NavigationNodeFooter[]
}

export const useNavigationStore = defineStore('navigation', {
	state: () => ({
		navigation: {} as NavigationNodes,
	}),

	actions: {
		async fetch() {
			const { data } = await useCachedQuery<NavigationQuery>({
				key: 'navigation',
				query: NavigationDocument,
			})

			this.navigation = data.value
				? (data.value as unknown as NavigationNodes)
				: ({} as NavigationNodes)
		},
	},
})
