<script setup lang="ts">
import { onClickOutside } from '@vueuse/core'

const props = withDefaults(
	defineProps<{
		fullHeight?: boolean
		withCloseButton?: boolean
	}>(),
	{
		withCloseButton: false,
		fullHeight: false,
	},
)
const emit = defineEmits(['close'])

const DURATION = 500
const isBackgroundBlurred = ref(false)

const drawerContainer = ref(null)

onClickOutside(drawerContainer, (event) => {
	if (isBackgroundBlurred.value) {
		emit('close')
	}
})

const isAPublication = useIsAPublication()
</script>

<template>
	<Transition
		:duration="DURATION"
		appear
		@beforeEnter="() => (isBackgroundBlurred = true)"
		@beforeLeave="() => (isBackgroundBlurred = false)"
	>
		<div
			class="drawer"
			:class="{
				'drawer--full': fullHeight,
				'drawer--publication': isAPublication,
			}"
		>
			<OverlayBackground
				v-show="isBackgroundBlurred"
				:duration="DURATION"
			/>

			<div
				ref="drawerContainer"
				class="drawer__container"
				:class="fullHeight ? 'h-screen' : ''"
				:style="`transition-duration: ${DURATION}ms`"
			>
				<CloseButton
					v-if="withCloseButton"
					class="absolute top-3 right-3 z-10"
					color="gray"
					@click="() => emit('close')"
				/>
				<div class="drawer__content">
					<slot></slot>
				</div>
			</div>
		</div>
	</Transition>
</template>

<style scoped lang="postcss">
.drawer {
	@apply fixed top-0 left-0 w-screen h-screen z-40;

	&__container {
		@apply absolute left-0 bottom-0 w-screen transition-transform  sm:max-h-[80vh];
	}

	&__content {
		@apply h-full sm:max-h-[80vh] sm:overflow-y-auto;
	}
}

.drawer--full .drawer__container,
.drawer--full .drawer__content {
	@apply sm:!max-h-[100vh];
}
.drawer--publication .drawer__container {
	@apply w-[calc(100vw_-_30px)] sm:w-[calc(100vw_-_36px)];
}

.v-enter-from,
.v-leave-to {
	.drawer__container {
		@apply translate-y-full;
	}
}

.v-enter-active .drawer__container {
	transition-delay: 200ms;
}
</style>
