<script setup lang="ts">
import { useScrollLock } from '@vueuse/core'

const props = defineProps<{
	duration: number
}>()

const el = ref<HTMLElement | null>(null)
const isLocked = useScrollLock(el)

onBeforeMount(() => {
	el.value = document.documentElement
})

onBeforeUnmount(() => {
	isLocked.value = false
	if (el.value) el.value.style.overflow = ''
})

onUnmounted(() => {
	isLocked.value = false
	if (el.value) el.value.style.overflow = ''
})

const emit = defineEmits<{
	(e: 'onBlurred'): void
}>()
</script>

<template>
	<Transition
		:duration="duration"
		name="blurred"
		@before-enter="
			() => {
				isLocked = true
				console.log('before enter')
			}
		"
		@after-enter="() => emit('onBlurred')"
		@after-leave="
			() => {
				isLocked = false
				if (el) el.style.overflow = ''
			}
		"
	>
		<div
			class="background"
			:style="`transition-duration: ${duration}ms;`"
		></div>
	</Transition>
</template>

<style scoped lang="postcss">
.background {
	@apply absolute top-0 left-0 w-screen h-screen bg-black/25 backdrop-blur-xl;
	transition-duration: 5000ms;
	transition-property: background, backdrop-filter;
}
.blurred-enter-active,
.blurred-leave-to {
	@apply bg-black/0 backdrop-blur-none;
}
.blurred-enter-to {
	@apply bg-black/25 backdrop-blur-xl;
}
</style>
