import type { Pausable } from '@vueuse/core'
import type { SpringControls } from '@vueuse/motion'

const ANIMATION_DURATION = 500

export class Tooltip {
	el: HTMLElement
	spring: SpringControls | undefined
	leaveTimeout: NodeJS.Timeout | undefined
	text = ''
	tooltipEl: Ref<HTMLDivElement | undefined> = ref()
	x = ref<number>(0)
	y = ref<number>(0)
	raf: Pausable | undefined
	position: 'left' | 'right' = 'right'
	width = 0
	type: 'external' | 'tooltip' | 'annotation'

	constructor(
		el: HTMLElement,
		text: string,
		type: 'external' | 'tooltip' | 'annotation',
	) {
		this.el = el
		const { x, y } = useMouse({ touch: false, type: 'client' })
		this.x = x
		this.y = y
		this.text = text
		this.type = type

		const { isMobile } = useDevice()

		if (isMobile) {
			el.addEventListener('click', this.onClick.bind(this))
		} else {
			el.addEventListener('mouseenter', this.onMouseEnter.bind(this))
			el.addEventListener('mouseleave', this.onMouseLeave.bind(this))
			el.addEventListener('mousemove', this.onMouseMove.bind(this))
		}

		this.raf = useRafFn(
			() => {
				const { tooltipEl, spring } = this
				if (!tooltipEl.value || !spring || !spring.values) return
				tooltipEl.value.style.left = `${spring.values.x}px`
				tooltipEl.value.style.top = `${spring.values.y}px`
				tooltipEl.value.style.transform = `scale(${spring.values.scale})`
			},
			{
				immediate: false,
			},
		)

		this.removeTooltipElement = this.removeTooltipElement.bind(this)
	}

	createTooltipElement() {
		const div = document.createElement('div')
		div.className = `nkl-tooltip nkl-tooltip--${this.type}`
		div.style.transformOrigin =
			this.type === 'annotation' ? 'center top' : 'left top'
		div.innerHTML = `<div>${this.text}</div>`
		document.body.appendChild(div)
		this.tooltipEl.value = div

		//@ts-ignore
		this.spring = useSpring(this.tooltipEl, {
			damping: 10,
			stiffness: 100,
			mass: 0.1,
		})

		return div
	}

	onMouseEnter() {
		const { leaveTimeout, raf, text, tooltipEl } = this
		if (!raf) return

		if (raf.isActive.value) {
			clearTimeout(leaveTimeout)
			this.spring?.set({
				scale: 1,
			})
		} else {
			const div = this.createTooltipElement()
			if (!this.spring) throw new Error('No spring')

			div.style.left = `${this.x.value}px`
			div.style.top = `${this.y.value}px`
			this.spring.values.x = this.x.value
			this.spring.values.y = this.y.value
			this.spring.values.scale = 0

			this.width = div.getBoundingClientRect().width

			if (
				this.type !== 'annotation' &&
				this.el.getBoundingClientRect().right + this.width >
					window.innerWidth
			) {
				this.position = 'left'
				div.style.transformOrigin = 'right top'
				div.style.left = `${this.x.value - this.width}px`
				this.spring.values.x = this.x.value - this.width
			}

			raf.resume()

			this.spring.set({
				scale: 1,
			})
		}
	}

	onMouseLeave() {
		const { spring, raf, tooltipEl } = this
		if (!spring || !raf) return

		spring.set({
			scale: 0,
		})

		this.leaveTimeout = setTimeout(() => {
			if (!tooltipEl.value) return
			document.body.removeChild(tooltipEl.value)
			raf.pause()
		}, ANIMATION_DURATION)
	}

	onMouseMove() {
		const offset = 5
		let left = this.x.value + offset
		if (this.position === 'left') left = this.x.value - this.width
		if (this.type === 'annotation') {
			left = Math.max(this.x.value - this.width / 2, 0)
			if (left + this.width > window.innerWidth)
				left = window.innerWidth - this.width
		}

		this.spring?.set({
			x: left,
			y: this.y.value + offset,
		})
	}

	// handle mobile version for annotations
	onClick() {
		const div = this.createTooltipElement()
		if (!this.spring || !this.raf) return
		if (this.type !== 'annotation') return

		this.raf.resume()
		this.spring.values.y = this.el.getBoundingClientRect().bottom + 5
		this.spring.values.x = 10
		this.spring.set({
			scale: 1,
		})
	}

	update(text: string) {
		if (!this.tooltipEl.value) return
		this.tooltipEl.value.innerHTML = text
	}

	removeTooltipElement() {
		const { tooltipEl } = this
		if (tooltipEl.value && document.body.contains(tooltipEl.value)) {
			if (this.leaveTimeout) clearTimeout(this.leaveTimeout)
			document.body.removeChild(tooltipEl.value)
			this.tooltipEl.value = undefined
		}
	}

	destroy() {
		const { el, raf, tooltipEl } = this
		raf?.pause()
		el.removeEventListener('mouseenter', this.onMouseEnter.bind(this))
		el.removeEventListener('click', this.onClick.bind(this))
		el.removeEventListener('mouseleave', this.onMouseLeave.bind(this))
		el.removeEventListener('mousemove', this.onMouseMove.bind(this))
		this.removeTooltipElement()
	}
}

export default defineNuxtPlugin((nuxtApp) => {
	const instances: Tooltip[] = []
	nuxtApp.vueApp.directive('tooltip', {
		mounted(el: HTMLElement, binding, vnode, prevVnode) {
			instances.push(new Tooltip(el, binding.value, 'tooltip'))
		},

		updated(el: HTMLElement, binding, vnode, prevVnode) {
			const instance = instances.filter((t) => t.el === el).at(0)
			if (instance) instance.update(binding.value)
		},

		unmounted(el: HTMLElement) {
			const instance = instances.filter((t) => t.el === el).pop()
			instance?.destroy()
		},
		getSSRProps(binding, vnode) {
			// you can provide SSR-specific props here
			return {}
		},
	})
})
