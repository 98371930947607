import { SettingsDocument, type SettingsQuery } from '~/generated/operations'

type SettingsData = NonNullable<SettingsQuery['settings']>

export const useSettingsStore = defineStore('settings', () => {
	const data = ref<SettingsData>()

	return {
		fetchData: async () => {
			const { data: settingsData } = await useCachedQuery<SettingsQuery>({
				key: 'settings',
				query: SettingsDocument,
			})
			if (settingsData.value.settings)
				data.value = settingsData.value.settings
		},
		data,
		// TODO: Decide if this is set on author
		authorBackgroundColors: [
			'!bg-nemo-cyan',
			'!bg-nemo-yellow',
			'!bg-nemo-magenta',
		],
	}
})
