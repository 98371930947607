import { default as _91_46_46_46slug_93lC3YAuXP5nMeta } from "/opt/APPS/nemo_kennislink/dev/www/source/frontend/pages/[...slug].vue?macro=true";
import { default as _91slug_933jxeEmWQ7XMeta } from "/opt/APPS/nemo_kennislink/dev/www/source/frontend/pages/facesofscience/profielen/[slug].vue?macro=true";
import { default as _91slug_93CHM1iWkRZFMeta } from "/opt/APPS/nemo_kennislink/dev/www/source/frontend/pages/ga/[slug].vue?macro=true";
import { default as indexvtBLoRBzkRMeta } from "/opt/APPS/nemo_kennislink/dev/www/source/frontend/pages/index.vue?macro=true";
import { default as _91slug_93trZoyfHCKfMeta } from "/opt/APPS/nemo_kennislink/dev/www/source/frontend/pages/over-ons/partners/[slug].vue?macro=true";
import { default as _91slug_93QTDFLmki9eMeta } from "/opt/APPS/nemo_kennislink/dev/www/source/frontend/pages/profielen/[slug].vue?macro=true";
import { default as _91slug_930CEeP9L8P0Meta } from "/opt/APPS/nemo_kennislink/dev/www/source/frontend/pages/publicaties/[slug].vue?macro=true";
import { default as _91slug_93eDI3gsbSvnMeta } from "/opt/APPS/nemo_kennislink/dev/www/source/frontend/pages/t/[slug].vue?macro=true";
import { default as indexwqjA6vm1wZMeta } from "/opt/APPS/nemo_kennislink/dev/www/source/frontend/pages/themas/[slug]/index.vue?macro=true";
import { default as publicatiesJeZUlrP4zFMeta } from "/opt/APPS/nemo_kennislink/dev/www/source/frontend/pages/themas/[slug]/publicaties.vue?macro=true";
import { default as indexUNdNJrUmQoMeta } from "/opt/APPS/nemo_kennislink/dev/www/source/frontend/pages/themas/index.vue?macro=true";
import { default as _91slug_93NssAIkmXtSMeta } from "~/pages/publicaties/[slug].vue?macro=true";
export default [
  {
    name: _91_46_46_46slug_93lC3YAuXP5nMeta?.name ?? "slug",
    path: _91_46_46_46slug_93lC3YAuXP5nMeta?.path ?? "/:slug(.*)*",
    meta: _91_46_46_46slug_93lC3YAuXP5nMeta || {},
    alias: _91_46_46_46slug_93lC3YAuXP5nMeta?.alias || [],
    redirect: _91_46_46_46slug_93lC3YAuXP5nMeta?.redirect,
    component: () => import("/opt/APPS/nemo_kennislink/dev/www/source/frontend/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_933jxeEmWQ7XMeta?.name ?? "facesofscience-profielen-slug",
    path: _91slug_933jxeEmWQ7XMeta?.path ?? "/facesofscience/profielen/:slug()",
    meta: _91slug_933jxeEmWQ7XMeta || {},
    alias: _91slug_933jxeEmWQ7XMeta?.alias || [],
    redirect: _91slug_933jxeEmWQ7XMeta?.redirect,
    component: () => import("/opt/APPS/nemo_kennislink/dev/www/source/frontend/pages/facesofscience/profielen/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93CHM1iWkRZFMeta?.name ?? "ga-slug",
    path: _91slug_93CHM1iWkRZFMeta?.path ?? "/ga/:slug()",
    meta: _91slug_93CHM1iWkRZFMeta || {},
    alias: _91slug_93CHM1iWkRZFMeta?.alias || [],
    redirect: _91slug_93CHM1iWkRZFMeta?.redirect,
    component: () => import("/opt/APPS/nemo_kennislink/dev/www/source/frontend/pages/ga/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexvtBLoRBzkRMeta?.name ?? "index",
    path: indexvtBLoRBzkRMeta?.path ?? "/",
    meta: indexvtBLoRBzkRMeta || {},
    alias: indexvtBLoRBzkRMeta?.alias || [],
    redirect: indexvtBLoRBzkRMeta?.redirect,
    component: () => import("/opt/APPS/nemo_kennislink/dev/www/source/frontend/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93trZoyfHCKfMeta?.name ?? "over-ons-partners-slug",
    path: _91slug_93trZoyfHCKfMeta?.path ?? "/over-ons/partners/:slug()",
    meta: _91slug_93trZoyfHCKfMeta || {},
    alias: _91slug_93trZoyfHCKfMeta?.alias || [],
    redirect: _91slug_93trZoyfHCKfMeta?.redirect,
    component: () => import("/opt/APPS/nemo_kennislink/dev/www/source/frontend/pages/over-ons/partners/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93QTDFLmki9eMeta?.name ?? "profielen-slug",
    path: _91slug_93QTDFLmki9eMeta?.path ?? "/profielen/:slug()",
    meta: _91slug_93QTDFLmki9eMeta || {},
    alias: _91slug_93QTDFLmki9eMeta?.alias || [],
    redirect: _91slug_93QTDFLmki9eMeta?.redirect,
    component: () => import("/opt/APPS/nemo_kennislink/dev/www/source/frontend/pages/profielen/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_930CEeP9L8P0Meta?.name ?? "publicaties-slug",
    path: _91slug_930CEeP9L8P0Meta?.path ?? "/publicaties/:slug()",
    meta: _91slug_930CEeP9L8P0Meta || {},
    alias: _91slug_930CEeP9L8P0Meta?.alias || [],
    redirect: _91slug_930CEeP9L8P0Meta?.redirect,
    component: () => import("/opt/APPS/nemo_kennislink/dev/www/source/frontend/pages/publicaties/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93eDI3gsbSvnMeta?.name ?? "t-slug",
    path: _91slug_93eDI3gsbSvnMeta?.path ?? "/t/:slug()",
    meta: _91slug_93eDI3gsbSvnMeta || {},
    alias: _91slug_93eDI3gsbSvnMeta?.alias || [],
    redirect: _91slug_93eDI3gsbSvnMeta?.redirect,
    component: () => import("/opt/APPS/nemo_kennislink/dev/www/source/frontend/pages/t/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexwqjA6vm1wZMeta?.name ?? "themas-slug",
    path: indexwqjA6vm1wZMeta?.path ?? "/themas/:slug()",
    meta: indexwqjA6vm1wZMeta || {},
    alias: indexwqjA6vm1wZMeta?.alias || [],
    redirect: indexwqjA6vm1wZMeta?.redirect,
    component: () => import("/opt/APPS/nemo_kennislink/dev/www/source/frontend/pages/themas/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: publicatiesJeZUlrP4zFMeta?.name ?? "themas-slug-publicaties",
    path: publicatiesJeZUlrP4zFMeta?.path ?? "/themas/:slug()/publicaties",
    meta: publicatiesJeZUlrP4zFMeta || {},
    alias: publicatiesJeZUlrP4zFMeta?.alias || [],
    redirect: publicatiesJeZUlrP4zFMeta?.redirect,
    component: () => import("/opt/APPS/nemo_kennislink/dev/www/source/frontend/pages/themas/[slug]/publicaties.vue").then(m => m.default || m)
  },
  {
    name: indexUNdNJrUmQoMeta?.name ?? "themas",
    path: indexUNdNJrUmQoMeta?.path ?? "/themas",
    meta: indexUNdNJrUmQoMeta || {},
    alias: indexUNdNJrUmQoMeta?.alias || [],
    redirect: indexUNdNJrUmQoMeta?.redirect,
    component: () => import("/opt/APPS/nemo_kennislink/dev/www/source/frontend/pages/themas/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93NssAIkmXtSMeta?.name ?? "events-slug",
    path: _91slug_93NssAIkmXtSMeta?.path ?? "/events/:slug",
    meta: _91slug_93NssAIkmXtSMeta || {},
    alias: _91slug_93NssAIkmXtSMeta?.alias || [],
    redirect: _91slug_93NssAIkmXtSMeta?.redirect,
    component: () => import("~/pages/publicaties/[slug].vue").then(m => m.default || m)
  }
]