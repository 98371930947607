import { useHeaderVisible } from '../composables/states'

export default defineNuxtPlugin((nuxtApp) => {
	nuxtApp.hook('page:finish', () => {
		if (typeof window !== 'undefined') {
			const el = document.querySelector('.logo-toggler') as HTMLElement
			if (!el) {
				useHeaderVisible().value = false
			} else {
				useHeaderVisible().value = true
			}
		}
	})
})
