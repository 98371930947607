<script setup lang="ts">
import type { RendererNode, RendererElement } from 'vue'
import { slideUp } from '~/utils/motionVariants'
import { vElementVisibility } from '@vueuse/components'

const props = withDefaults(
	defineProps<{
		currentIndex: number
		amount: number
		type?: 'dotted' | 'numbered'
		mode?: 'dark' | 'light'
		loop?: boolean
	}>(),
	{
		type: 'dotted',
		mode: 'dark',
		loop: true,
	},
)

const MAX_DOTS = 7
const startIndex = ref(0)
const dotsRef = ref()
const numDots = computed(() => {
	if (props.amount > MAX_DOTS) return MAX_DOTS
	return props.amount
})

watch(
	() => props.currentIndex,
	(newVal, oldVal) => {
		if (props.amount <= MAX_DOTS || props.type !== 'dotted') return

		const threshold = Math.floor(MAX_DOTS / 2)
		if (newVal === 0 && oldVal === props.amount - 1) {
			// loop back to beginning
			startIndex.value = 0
		} else if (newVal === props.amount - 1 && oldVal === 0) {
			// go from start to end
			startIndex.value = numDots.value + 1 - threshold
		} else if (
			newVal > oldVal &&
			newVal > startIndex.value + threshold &&
			newVal < props.amount - threshold
		) {
			startIndex.value = startIndex.value + 1
		} else if (newVal < startIndex.value + numDots.value - threshold - 1) {
			if (startIndex.value - 1 >= 0)
				startIndex.value = startIndex.value - 1
		}
	},
)

const emit = defineEmits<{
	(e: 'pressed-prev'): void
	(e: 'pressed-next'): void
}>()

const prevDisabled = computed(() => !props.loop && props.currentIndex === 0)
const nextDisabled = computed(
	() => !props.loop && props.currentIndex === props.amount - 1,
)
const prevButtonEl = ref()
const nextButtonEl = ref()
const { apply: applyPrev } = useMotion(prevButtonEl, {
	...slideUp({
		delay: 50,
		visibleOnce: { opacity: prevDisabled.value ? 0.25 : 1 },
	}),
})
const { apply: applyNext } = useMotion(nextButtonEl, {
	...slideUp({
		delay: props.type === 'dotted' ? (numDots.value + 1) * 100 : 100,
		visibleOnce: { opacity: nextDisabled.value ? 0.25 : 1 },
	}),
})

const onElementVisibility = (index: number) => {
	if (!dotsRef.value[index]) return

	useMotion(
		dotsRef.value[index] as HTMLElement,
		slideUp({
			initial: {
				y: 10,
			},
			duration: 300,
			delay: index * 100,
		}),
	)
}

watch(
	() => props.currentIndex,
	() => {
		applyPrev({ opacity: prevDisabled.value ? 0.25 : 1 })
		applyNext({
			opacity: nextDisabled.value ? 0.25 : 1,
		})
	},
)

const isOnThemePage = computed(() => {
	return useRoute().name === 'themas-slug'
})
</script>

<template>
	<div
		class="pagination"
		:class="`pagination--${isOnThemePage ? 'light' : props.mode}`"
	>
		<button
			class="pagination__button prev mr-2"
			@click="$emit('pressed-prev')"
			:disabled="prevDisabled"
			ref="prevButtonEl"
			@mouseenter="() => applyPrev({ scale: 1.1 })"
			@mouseleave="() => applyPrev({ scale: 1 })"
			aria-label="Vorige item"
		>
			<img
				alt="Links"
				src="@/assets/icons/arrow-left.svg?inline"
				class="h-full w-full"
			/>
		</button>

		<div v-if="type === 'dotted'" class="pagination__dots">
			<ul
				class="transition-transform"
				:style="{
					transform: `translateX(${-startIndex * 20}px)`,
				}"
			>
				<li
					v-for="index in amount"
					ref="dotsRef"
					:class="{
						selected: index - 1 === currentIndex,
						'out-of-range': index > MAX_DOTS,
						'has-more-before':
							index > 1 && index === startIndex + 1,
						'has-more-after':
							index < amount && index - startIndex === numDots,
					}"
					v-element-visibility="
						(state) => {
							if (state && index <= MAX_DOTS)
								onElementVisibility(index)
						}
					"
				></li>
			</ul>
		</div>

		<div v-if="type === 'numbered'" class="pagination__numbered">
			<span class="pagination__numbered__current">{{
				currentIndex + 1
			}}</span>
			<span> | {{ amount }}</span>
		</div>

		<button
			class="pagination__button next ml-2"
			@click="$emit('pressed-next')"
			:disabled="nextDisabled"
			ref="nextButtonEl"
			@mouseenter="() => applyNext({ scale: 1.1 })"
			@mouseleave="() => applyNext({ scale: 1 })"
			aria-label="Volgende item"
		>
			<img
				alt="Rechts"
				src="@/assets/icons/arrow-right.svg?inline"
				class="h-full w-full"
			/>
		</button>
	</div>
</template>

<style scoped>
.pagination {
	@apply flex items-center;
}

.pagination--light .pagination__dots > ul > li {
	@apply bg-white/30;
}
.pagination--light .pagination__dots > ul > li.selected {
	@apply bg-white;
}

.pagination__button {
	@apply inline-block rounded-full bg-nemo-green w-9 h-9 p-2;
}

.pagination__button:disabled {
	opacity: 0.25;
}

.pagination__dots {
	@apply max-w-[140px] h-full overflow-hidden mx-2 flex items-center;
}

.pagination__dots > ul {
	@apply leading-none whitespace-nowrap h-[16px];
}

.pagination__dots > ul > li {
	@apply inline-block w-2 h-2 bg-black/30 rounded-full mx-1.5 duration-200 transition-all;
}

.pagination__dots ul > li.selected {
	@apply bg-black;
}

.pagination__dots ul > li.has-more-after,
.pagination__dots ul > li.has-more-before {
	@apply !scale-50;
}

.pagination__numbered {
	@apply mx-4 whitespace-nowrap;
}

.pagination__numbered__current {
	@apply font-semibold;
}
</style>
