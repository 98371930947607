<script setup lang="ts">
const props = defineProps<{ type: string | null; size: string }>()

const className = computed(
	() =>
		`label--${
			props.type
				? props.type
				: 'unknown'.toLowerCase().replace(/ /gi, '-')
		} label--${
			props.size
				? props.size
				: 'unknown'.toLowerCase().replace(/ /gi, '-')
		}`,
)

const typeMap: Record<string, any> = {
	publicaties_facesOfScience_Entry: 'Faces of Science',
	publicaties_podcast_Entry: 'Podcast',
	publicaties_video_Entry: 'Video',
	publicaties_event_Entry: 'Event',
	publicaties_quiz_Entry: 'Quiz',
	publicaties_slideshow_Entry: 'Slideshow',
	publicaties_poll_Entry: 'Poll',
}
const resolvedType: string = props.type in typeMap ? typeMap[props.type] : false
</script>

<template>
	<div
		v-if="resolvedType"
		class="label inline-flex bg-white items-center rounded-r-full mb-1 transition-label"
		:class="className"
	>
		<div
			class="label__icon bg-nemo-green flex flex-col justify-center items-center mr-1 transition-label"
		>
			<div
				class="label__icon-inner bg-black rounded-full transition-label"
			>
				<img
					:src="`/images/icons/types/${resolvedType
						.toLowerCase()
						.replace(/ /g, '')}.svg`"
					:alt="resolvedType"
					class="w-full h-full"
				/>
			</div>
		</div>
		<div
			class="label__text uppercase leading-none text-black transition-label"
		>
			{{ typeMap[type] }}
		</div>
	</div>
</template>

<style scoped>
.transition-label {
	@apply transition-all duration-1000 ease-in-out;
}
.label--small {
	@apply pr-1;
}
.label--small .label__icon {
	@apply w-[11px] h-[11px];
}
.label--small .label__icon-inner {
	@apply w-[9px] h-[9px];
}
.label--small .label__text {
	@apply text-[9px] h-[10px] pt-[2px] pb-[0px] leading-none;
}
.label--medium {
	@apply pr-1.5;
}
.label--medium .label__icon {
	@apply w-[16px] h-[16px];
}
.label--medium .label__icon-inner {
	@apply w-[14px] h-[14px];
}

.label--medium .label__text {
	@apply text-[12px]  h-auto pt-[5px] pb-[1px] leading-[0];
}
.label--large {
	@apply pr-2;
}
.label--large .label__icon {
	@apply w-[20px] h-[20px] md:w-[24px] md:h-[24px];
}
.label--large .label__icon-inner {
	@apply w-[16px] h-[16px] md:w-[20px] md:h-[20px];
}
.label--large .label__text {
	@apply text-[15px] md:text-body h-[11px] md:h-[14px];
}
</style>
