export const useLastRouteStore = defineStore('lastRoute', () => {
	const lastRoute = ref<string | undefined>(undefined)

	const setLastRoute = (route: string) => {
		lastRoute.value = route
	}

	return {
		lastRoute,
		setLastRoute,
	}
})
