export default defineNuxtPlugin((nuxtApp) => {
	nuxtApp.vueApp.directive('logo-toggler', {
		mounted(el: HTMLElement, binding, vnode, prevVnode) {
			const isVisible = useElementVisibility(el as HTMLElement)
			el.classList.add('logo-toggler')
			watchImmediate(isVisible, (visible) => {
				const currentEl = document.querySelector(
					'.logo-toggler',
				) as HTMLElement
				if (currentEl === el) {
					useHeaderVisible().value = visible
				}
			})
		},

		unmounted(el: HTMLElement) {
			useHeaderVisible().value = true
		},
		getSSRProps(binding, vnode) {
			// you can provide SSR-specific props here
			return {}
		},
	})
})
