<script setup lang="ts">
import type { PublicationsQuery } from '~/generated/operations'
import { PublicationsDocument } from '~/generated/operations'
import type { PublicationsQueryResult } from '~/utils/types'

const { isMobile } = useDevice()
const { x, y } = useMouse({ touch: false, type: 'client' })

const props = withDefaults(
	defineProps<{
		links: NodeListOf<HTMLLinkElement>
		className?: string
	}>(),
	{
		className: 'z-[1000]',
	},
)

const DURATION = 500
const slugs = ref<string[]>([])
const cards = ref<PublicationsQueryResult>()
const activeCardSlug = ref<string>()
const isActive = ref(false)
const card = computed(() =>
	cards.value?.filter((c: any) => c.slug === activeCardSlug.value).pop(),
)
const { width: windowWidth, height: windowHeight } = useWindowSize()

watch(slugs, async () => {
	if (isMobile || slugs.value.length === 0) return
	const { data } = await useCachedQuery<PublicationsQuery>({
		key: `publication-link-cards:${[...new Set(slugs.value)]
			.sort()
			.join(',')}`,
		query: PublicationsDocument,
		variables: {
			slugs: slugs.value,
		},
		tags: slugs.value.map((s) => `publication:${s}`),
	})
	cards.value = data.value?.entries as PublicationsQueryResult
})

const onMouseEnter = (e: MouseEvent) => {
	const target = e.target as HTMLLinkElement
	activeCardSlug.value = target.dataset.slug
	isActive.value = true
}

const onMouseLeave = (e: MouseEvent) => {
	isActive.value = false
}

watchImmediate(
	() => props.links,
	(newLinks, oldLinks) => {
		oldLinks?.forEach((l) => {
			l.removeEventListener('mouseenter', onMouseEnter)
			l.removeEventListener('mouseleave', onMouseLeave)
		})

		const newSlugs: string[] = []
		newLinks.forEach((l) => {
			const slug = new URL(l.href).pathname
				.split('/')
				.filter(Boolean)
				.pop()!
			newSlugs.push(slug)
			l.dataset.slug = slug
			l.addEventListener('mouseenter', onMouseEnter)
			l.addEventListener('mouseleave', onMouseLeave)
		})
		slugs.value = newSlugs
	},
)

const cardEl = ref<HTMLElement>()
const { apply, stop } = useMotion(cardEl)
watch([x, y], ([newX, newY]) => {
	if (!cardEl.value || !activeCardSlug.value) return

	let offset = 10
	let xPos = newX + offset
	let yPos = newY + offset
	if (windowWidth.value - newX < 290) xPos = newX - offset - 290
	if (windowHeight.value - newY < 400) yPos = newY - offset - 400

	stop()
	apply({
		left: `${Math.round(xPos)}px`,
		top: `${Math.round(yPos)}px`,
		transition: {
			damping: 10,
			stiffness: 100,
			mass: 0.1,
		},
	})
})

const onAfterLeave = () => {
	if (!isActive.value) activeCardSlug.value = undefined
}
</script>

<template>
	<Transition
		:duration="DURATION"
		@after-leave="onAfterLeave"
		:key="activeCardSlug"
	>
		<div
			class="hover-card-container"
			:class="className"
			v-show="card && isActive"
			ref="cardEl"
			:style="{
				transitionDuration: `${DURATION}ms`,
			}"
		>
			<CardFront v-if="card" :standalone="true" :publication="card" />
		</div>
	</Transition>

	<!-- Preloading the card images -->
	<ClientOnly>
		<div class="absolute top-[-500px] left-[-300px]">
			<CardFront
				v-for="card in cards"
				:standalone="true"
				:publication="card"
			/>
		</div>
	</ClientOnly>
</template>

<style scoped lang="postcss">
.hover-card-container {
	@apply fixed h-[400px] overflow-hidden pointer-events-none;
	transition-property: height;
}

.v-enter-from,
.v-leave-to {
	@apply h-0;
}
</style>
