import { defineStore } from 'pinia'
import {
	TextsDocument,
	type TextsQuery,
	type Texts_GlobalSet,
} from '~/generated/operations'

export const useTextsStore = defineStore('texts', {
	state: () => ({
		texts: {} as Texts_GlobalSet,
	}),

	actions: {
		async fetch() {
			const { data } = await useCachedQuery<TextsQuery>({
				key: 'texts',
				query: TextsDocument,
			})

			this.texts = data.value
				? (data.value as unknown as Texts_GlobalSet)
				: ({} as Texts_GlobalSet)
		},
	},
})
