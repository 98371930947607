import { createHttpLink } from '@apollo/client/core'
import { setContext } from '@apollo/client/link/context'
import { provideApolloClient } from '@vue/apollo-composable'

export default defineNuxtPlugin((nuxtApp) => {
	nuxtApp.hook('vue:setup', () => {
		const config = useRuntimeConfig()
		const httpLink = createHttpLink({
			uri: config.public.graphQlEndpoint,
		})

		const params = useRoute().query
		const token = params['token']?.toString()
		const authLink = setContext((_, { headers }) => {
			return {
				headers: {
					...headers,
					'X-Craft-Token': token,
				},
			}
		})

		const client = useApollo().clients?.default
		client?.setLink(authLink.concat(httpLink))
		provideApolloClient(client!)
		nuxtApp.hook('apollo:error', (error) => {
			console.error('Apollo error!')
			console.error(error)
		})
	})
})
