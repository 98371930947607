import { MotionPlugin, type MotionVariants } from '@vueuse/motion'
export default defineNuxtPlugin((nuxtApp) => {
	const customDirectives: Record<string, MotionVariants> = {
		'slide-up': {
			initial: { opacity: 0, y: 20 },
			visibleOnce: {
				opacity: 1,
				y: 0,
				transition: {
					type: 'keyframes',
					ease: 'easeOut',
					duration: 250,
					delay: 250,
				},
			},
		},
	}
	nuxtApp.vueApp.use(MotionPlugin, {
		directives: customDirectives,
	})
})
