import { createGtm, type VueGtmUseOptions } from '@gtm-support/vue-gtm'
import {
	PublicationDocument,
	ThemeDocument,
	type PublicationQuery,
	type ThemeQuery,
} from '~/generated/operations'

export default defineNuxtPlugin((nuxtApp) => {
/* 	if (process.client) {
		const router = useRouter()
		const pluginOptions: VueGtmUseOptions = {
			id: 'GTM-M58WFGD',
			vueRouter: router as VueGtmUseOptions['vueRouter'],
			debug: process.env.CRAFT_ENVIRONMENT !== 'production',
			trackViewEventProperty: 'virtual_page_view',
			vueRouterAdditionalEventData: async (to) => {
				const start = performance.now()
				let dimensions = [
					{
						name: 'dimension1',
						value: null,
					},
					{
						name: 'dimension2',
						value: null,
					},
					{
						name: 'dimension4',
						value: null,
					},
					{ name: 'dimension5', value: null },
					{ name: 'dimension9', value: null },
					{ name: 'dimension10', value: null },
					{
						name: 'dimension11',
						value: null,
					},
					{
						name: 'dimension12',
						value: null,
					},
				]

				if (to.name === 'publicaties-slug') {
					const normalizedPath = fullPathToUri(to.fullPath)
					const { data } = await useCachedQuery<PublicationQuery>({
						key: `publication:${normalizedPath}`,
						query: PublicationDocument,
						variables: {
							uri: normalizedPath,
						},
						maxAge: 60 * 60 * 24, // 1 day
					})

					const publication = data.value
						.entry as PublicationQueryResult

					let count = 0
					if (publication.introduction) {
						count += publication.introduction
							.replace(/(<([^>]+)>)/gi, '')
							.split(' ').length
					}
					if (publication.matrix) {
						Object.values(publication.matrix).forEach(function (m) {
							if (typeof m.wysiwygContent !== 'undefined') {
								count += m.wysiwygContent
									.replace(/(<([^>]+)>)/gi, '')
									.split(' ').length
							}
						})
					}

					dimensions = [
						{
							name: 'dimension1',
							value: publication.profiles
								.map((obj) => obj.title)
								.join(' ,'),
						},
						{
							name: 'dimension2',
							value: publication.fieldsOfExpertise
								.map((obj) => obj.title)
								.join(' ,'),
						},
						{
							name: 'dimension4',
							value: publication.themes
								.map((obj) => obj.title)
								.join(' ,'),
						},
						{ name: 'dimension5', value: publication.dateYmd },
						{ name: 'dimension9', value: 'publication' },
						{ name: 'dimension10', value: count },
						{
							name: 'dimension11',
							value: publication.series
								.map((obj) => obj.title)
								.join(' ,'),
						},
						{
							name: 'dimension12',
							value: useDimensionfyTypes(publication.typeHandle),
						},
					]
				} else if (to.name === 'themas-slug') {
					const slug = to.params.slug as string

					const { data } = await useCachedQuery<ThemeQuery>({
						key: `theme:${slug}`,
						query: ThemeDocument,
						variables: {
							slug,
						},
						maxAge: 60 * 60 * 24, // 1 day
					})

					const theme = data.value.entry

					for (let i = 0; i < dimensions.length; i++) {
						if (dimensions[i].name == 'dimension4') {
							dimensions[i].value = theme.title
						}
					}
				}

				dimensions = dimensions.reduce(
					(obj, item) => {
						obj[item.name] = item.value
						return obj
					},
					{} as Record<string, string>,
				)

				return dimensions
			},
		}

		nuxtApp.vueApp.use(createGtm(pluginOptions))
	} */
})
