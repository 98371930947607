export const useLogoWhiteMode = () => {
	return useState<boolean>(
		'logoWhiteMode',
		() => useRoute().meta.logoWhiteMode ?? false,
	)
}

export const useHeaderVisible = () => {
	return useState<boolean>('isHeaderVisible', () =>
		ref(useRoute().name !== 'index'),
	)
}
