export const useFallbackColor = (id: number) => {
	const fallbackColors = [
		'#9DF2A3',
		'#9DF2E3',
		'#9DC2F2',
		'#B89DF2',
		'#F29DAD',
		'#F2CE9D',
		'#D7F29D',
	]
	return fallbackColors[id % fallbackColors.length]
}
