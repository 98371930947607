import validate from "/opt/APPS/nemo_kennislink/dev/www/source/frontend/node_modules/nuxt/dist/pages/runtime/validate.js";
import last_45route_45global from "/opt/APPS/nemo_kennislink/dev/www/source/frontend/middleware/last-route.global.ts";
import layout_45global from "/opt/APPS/nemo_kennislink/dev/www/source/frontend/middleware/layout.global.ts";
import manifest_45route_45rule from "/opt/APPS/nemo_kennislink/dev/www/source/frontend/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  last_45route_45global,
  layout_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {}