import type { MotionVariants } from '@vueuse/motion'

const slideUpPreset: MotionVariants = {
	initial: { opacity: 0, y: 20 },
	visibleOnce: {
		opacity: 1,
		y: 0,
		transition: {
			type: 'keyframes',
			ease: [0.12, 0.94, 0.45, 0.98],
			duration: 500,
			delay: 0,
		},
	},
}

type SlideUpParameters = {
	initial?: (typeof slideUpPreset)['initial']
	visibleOnce?: (typeof slideUpPreset)['visibleOnce']
	delay?: number
	duration?: number
}

export const slideUp = (params?: SlideUpParameters) => {
	const variant = {
		initial: {
			...slideUpPreset.initial,
			...params?.initial,
		},
		visibleOnce: {
			...slideUpPreset.visibleOnce,
			...params?.visibleOnce,
			transition: {
				...slideUpPreset.visibleOnce?.transition,
				...params?.visibleOnce?.transition,
				delay:
					params?.delay ??
					params?.visibleOnce?.transition?.delay ??
					slideUpPreset.visibleOnce?.transition.duration,
				duration:
					params?.duration ??
					params?.visibleOnce?.transition?.duration ??
					slideUpPreset.visibleOnce?.transition.duration,
			},
		},
	} as MotionVariants

	return variant
}
