<script setup lang="ts">
import type { S3_Asset } from '~/generated/operations'
import type { PublicationSummary } from './index.vue'

const props = defineProps<{
	publication: PublicationSummary
	standalone?: boolean
	disableClick?: boolean
	disableHoverClick?: boolean
	disableLazyLoading?: boolean
	fetchPriority?: boolean
}>()

const tag = props.standalone ? resolveComponent('NuxtLink') : 'button'
const image = props.publication.header_image?.length
	? props.publication.header_image[0]
	: null
const link = useInternalLink(props.publication.uri)
const fallbackColor = useFallbackColor(props.publication.id)

const quizImagePositionClasses = [
	'-top-8 -left-2 -rotate-12',
	'top-6 -right-12 rotate-12',
	'-bottom-0 -left-12 -rotate-12',
	'-bottom-8 -right-2 rotate-12',
]
</script>

<template>
	<component
		:is="tag"
		:to="standalone && !disableClick ? link : null"
		class="card__front backface"
		:class="{
			'relative w-[290px] h-auto aspect-[290/400] cursor-pointer hover:scale-[1.05] ':
				standalone,
			'absolute w-full h-full': !standalone,
			'duration-[.5s] transition-all ease-[cubic-bezier(.05,.92,.6,1.01)]':
				!disableHoverClick,
			'text-black':
				(publication.mode == 'dark' ||
					publication.__typename === 'publicaties_poll_Entry' ||
					publication.__typename === 'publicaties_quiz_Entry') &&
				publication.__typename !== 'publicaties_video_Entry' &&
				publication.__typename !== 'publicaties_podcast_Entry',
			'text-white':
				(publication.mode == 'light' ||
					publication.__typename === 'publicaties_podcast_Entry' ||
					publication.__typename === 'publicaties_video_Entry') &&
				publication.__typename !== 'publicaties_poll_Entry' &&
				publication.__typename !== 'publicaties_quiz_Entry',
			'justify-center':
				!image &&
				publication.__typename !== 'publicaties_podcast_Entry' &&
				publication.__typename !== 'publicaties_video_Entry' &&
				publication.__typename !== 'publicaties_poll_Entry' &&
				publication.__typename !== 'publicaties_quiz_Entry',
		}"
		:style="{
			backgroundColor:
				image && publication.color != ''
					? publication.color
					: fallbackColor,
		}"
	>
		<span
			class="overflow-hidden h-auto w-full grow"
			v-if="
				image &&
				publication.__typename != 'publicaties_poll_Entry' &&
				publication.__typename != 'publicaties_quiz_Entry'
			"
		>
			<span
				class="h-full transition-all duration-[.5s] ease-[cubic-bezier(.06,.86,.18,.93)] bg-center grow bg-cover scale-105"
				:class="{
					['group-hover:scale-100']: !disableHoverClick,
				}"
			>
				<NuxtImg
					class="h-full w-full object-cover"
					:src="`uploads/${image.filename}`"
					width="290"
					height="400"
					fit="crop"
					:loading="disableLazyLoading ? 'eager' : 'lazy'"
					:alt="image.alt ?? image.altImported ?? ''"
					:fetchpriority="fetchPriority ? fetchPriority : 'medium'"
					:modifiers="{
						auto: 'format,compress',
						fit: 'crop',
						crop: 'focalpoint',
						'fp-x': image.focalPoint ? image.focalPoint[0] : 0.5,
						'fp-y': image.focalPoint ? image.focalPoint[1] : 0.5,
					}"
				/>
			</span>
		</span>
		<span
			v-else-if="
				publication.__typename === 'publicaties_poll_Entry' ||
				publication.__typename === 'publicaties_quiz_Entry'
			"
			class="grow shrink-0 w-full h-full object-cover pointer-events-none flex flex-col justify-center items-center"
			:class="{
				'bg-nemo-yellow':
					publication.__typename === 'publicaties_poll_Entry',
				'bg-nemo-cyan':
					publication.__typename === 'publicaties_quiz_Entry',
			}"
		>
			<span
				v-if="publication.__typename === 'publicaties_quiz_Entry'"
				class="relative w-full h-full"
			>
				<NuxtImg
					v-for="(
						image, index
					) in publication.header_image as S3_Asset[]"
					:src="`uploads/${image.filename}`"
					class="absolute"
					:class="quizImagePositionClasses[index]!"
					width="120"
					height="105"
					fit="crop"
					loading="lazy"
					:alt="image.alt ?? image.altImported ?? ''"
					:modifiers="{
						auto: 'format,compress',
						fit: 'crop',
						crop: 'focalpoint',
						'fp-x': image.focalPoint![0],
						'fp-y': image.focalPoint![1],
					}"
				/>
			</span>
		</span>

		<span
			class="p-3 pt-3.5 relative after:z-0 after:content-[''] after:w-full after:h-full after:absolute after:top-0 after:left-0"
			:class="{
				'after:bg-gradient-to-b after:from-black/0 after:to-black/50':
					publication.__typename === 'publicaties_podcast_Entry' ||
					publication.__typename === 'publicaties_video_Entry',
				'!pt-2.5 !absolute w-full h-full text-center flex flex-col justify-center items-center ':
					publication.__typename === 'publicaties_quiz_Entry' ||
					publication.__typename === 'publicaties_poll_Entry' ||
					publication.__typename === 'publicaties_podcast_Entry' ||
					publication.__typename === 'publicaties_video_Entry',

				/* 'after:bg-white/10':
					image &&
					publication.mode === 'dark' &&
					publication.__typename !== 'publicaties_quiz_Entry' &&
					publication.__typename !== 'publicaties_poll_Entry' &&
					publication.__typename !== 'publicaties_podcast_Entry' &&
					publication.__typename !== 'publicaties_video_Entry',
				'after:bg-black/10':
					image &&
					publication.mode === 'light' &&
					publication.__typename !== 'publicaties_quiz_Entry' &&
					publication.__typename !== 'publicaties_poll_Entry' &&
					publication.__typename !== 'publicaties_podcast_Entry' &&
					publication.__typename !== 'publicaties_video_Entry', */
				'!pt-2.5':
					publication.__typename ==
						'publicaties_facesOfScience_Entry' ||
					publication.__typename == 'publicaties_event_Entry',
			}"
		>
			<Label
				:type="publication.__typename"
				size="medium"
				class="relative z-10 mb-3"
			></Label>
			<h3
				class="relative z-10 font-semibold !text-card leading-[1.1] break-words"
			>
				{{ publication.title || publication.id }}
			</h3>
			<!-- TODO: Format date and add in datetime attr -->
			<time
				v-if="publication.__typename == 'publicaties_event_Entry'"
				datetime=""
				class="relative z-10 text-small"
				>{{ publication.eventDate ? publication.eventDate : '' }}</time
			>
		</span>
	</component>
</template>

<style scoped lang="postcss">
.card__front {
	@apply z-[1] flex flex-col rounded-xl overflow-hidden select-none text-left whitespace-normal !no-underline;
	outline: 1px solid transparent;
}
</style>
