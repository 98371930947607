<script setup lang="ts">
import { KamagurkasDocument } from '~/generated/operations'
import type { KamagurkasQuery } from '~/generated/operations'

const props = defineProps<{
	mode?: 'light' | 'dark'
	pagination?: boolean
}>()

const { data } = await useCachedQuery<KamagurkasQuery>({
	key: 'kamagurkas',
	query: KamagurkasDocument,
})

const kamagurkas = ref(
	data?.value?.items?.kamagurkas
		.filter((item) => item.active)
		.map((value) => ({ value, sort: Math.random() }))
		.sort((a, b) => a.sort - b.sort)
		.map(({ value }) => value),
)
const currentIndex = ref(0)

const nextKamagurka = () => {
	currentIndex.value++
	if (currentIndex.value >= kamagurkas.value.length) {
		currentIndex.value = 0
	}
}

const prevKamagurka = () => {
	currentIndex.value--
	if (currentIndex.value < 0) {
		currentIndex.value = kamagurkas.value.length - 1
	}
}

const className = ref()

watchImmediate(currentIndex, () => {
	if (
		kamagurkas.value[currentIndex.value] &&
		kamagurkas.value[currentIndex.value].text.length < 40
	) {
		className.value = 'text-[10cqw]'
	} else if (
		kamagurkas.value[currentIndex.value] &&
		kamagurkas.value[currentIndex.value].text.length < 80
	) {
		className.value = 'text-[8.5cqw]'
	} else {
		className.value = 'text-[7cqw]'
	}
})

const tag = computed(() =>
	kamagurkas.value[currentIndex.value].publication?.length
		? resolveComponent('NuxtLink')
		: 'div',
)
const url = computed(() =>
	kamagurkas.value[currentIndex.value].publication?.length
		? useInternalLink(
				kamagurkas.value[currentIndex.value].publication[0].uri,
			)
		: null,
)

const urlItem = ref<NodeListOf<HTMLLinkElement>>()
const comp = ref(null)
const link = ref(null)

onMounted(() => {
	watchImmediate(url, () => {
		setTimeout(function () {
			urlItem.value = comp.value?.querySelectorAll<HTMLLinkElement>('a')
		}, 100)
	})
})
</script>
<template>
	<div
		ref="comp"
		class="flex flex-col justify-between items-center sm:h-screen w-full"
		:class="mode === 'dark' ? 'bg-black text-white' : 'bg-white text-black'"
		v-if="kamagurkas"
	>
		<slot name="prefix">
			<span class="h-12"></span>
		</slot>
		<div
			class="shrink-0 flex items-center flex-col sm:max-w-[650px] w-full px-5 sm:px-0 sm:w-4/5"
		>
			<KamagurkaIcon
				class="mb-[1em] sm:mb-[2em] w-8 sm:w-12 aspect-square"
			></KamagurkaIcon>
			<div class="max-w-[650px] w-full">
				<ClientOnly>
					<component
						:is="tag"
						:to="url"
						class="block w-full"
						ref="link"
						@click="$emit('clicked-publication')"
					>
						<blockquote
							class="max-w-[650px] w-full leading-none font-semibold text-center block text-[30px] sm:text-[50px]"
						>
							<p :class="className" class="tracking-tighter">
								{{ kamagurkas[currentIndex].text }}
							</p>
						</blockquote>
					</component>
				</ClientOnly>
			</div>
		</div>
		<MiscCarouselPagination
			v-if="pagination"
			class="py-8"
			:mode="mode === 'dark' ? 'light' : 'dark'"
			:current-index="currentIndex"
			:amount="kamagurkas.length"
			@pressed-next="nextKamagurka"
			@pressed-prev="prevKamagurka"
		/>
		<span v-else class="h-12"></span>

		<MatrixPublicationLinkCards
			className="z-[1]"
			v-if="urlItem"
			:links="urlItem"
		></MatrixPublicationLinkCards>
	</div>
</template>

<style scoped>
blockquote {
	container-type: inline-size;
}
</style>
