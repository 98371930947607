const isAPublication = ref(false)

export const useIsAPublication = function () {
	const route = useRoute()

	watch(
		() => route.path,
		() => {
			isAPublication.value =
				(route.path.startsWith('/publicaties/') &&
					route.path !== '/publicaties/') ||
				(route.path.startsWith('/events/') &&
					route.path !== '/events/' &&
					route.path !== '/events/archief')
		},
		{ deep: true, immediate: true },
	)

	return isAPublication
}
