import { useLogoWhiteMode } from '~/composables/states'
import { RouteDocument } from '~/generated/operations'
import type { RouteQueryResult } from '~/utils/types'

export default defineNuxtRouteMiddleware(async (to, from) => {
	const { path } = to
	const uri = path.replace(/^\/|\/$/g, '')

	const { data } = await useCachedQuery<{ entry: RouteQueryResult }>({
		key: `route:${uri}`,
		query: RouteDocument,
		variables: {
			uri,
		},
	})

	const forceWhiteRoutes = [
		'facesofscience',
		'facesofscience-profielen-slug',
		'themas-slug',
		'themas',
	]
	if (forceWhiteRoutes.includes(to.name as string)) {
		to.meta.logoWhiteMode = true
	} else if (data.value.entry) {
		to.meta.logoWhiteMode = data.value.entry.mode === 'light'
	} else {
		to.meta.logoWhiteMode = false
	}

	if (to.name === 'index') {
		useHeaderVisible().value = false
	} else {
		useHeaderVisible().value = true
	}

	useLogoWhiteMode().value = to.meta.logoWhiteMode

	const toSlug = to.params.slug
	const toFullSlug = Array.isArray(toSlug) ? toSlug[0] : toSlug
	const toIsQuiz = toFullSlug ? toFullSlug.startsWith('quiz-') : false
	const toIsSlideshow = toFullSlug
		? toFullSlug.startsWith('slideshow-')
		: false
	const fromSlug = from.params.slug
	const fromFullSlug = Array.isArray(fromSlug) ? fromSlug[0] : fromSlug
	const fromIsSlideshow = fromFullSlug
		? fromFullSlug.startsWith('slideshow-')
		: false
	const fromIsQuiz = fromFullSlug ? fromFullSlug.startsWith('quiz-') : false

	if (to.name === 'publicaties-slug')
		setPageLayout(
			toIsSlideshow ? 'slideshow' : toIsQuiz ? 'quiz' : 'publication',
		)

	// Transitions logic
	if (to.name === 'publicaties-slug' && (toIsSlideshow || toIsQuiz)) {
		const oldTransition = from.meta.layoutTransition
		if (!oldTransition) from.meta.layoutTransition = {}

		from.meta.layoutTransition = {
			name: 'blur-transition',
			onLeave: (el, done) => {
				from.meta.layoutTransition = oldTransition
				setTimeout(done, 1500)
			},
		}
		to.meta.layoutTransition = {
			name: toIsQuiz ? 'quiz-transition' : 'slideshow-transition',
			onEnter: (el, done) => {
				document.documentElement.style.overflow = 'hidden'
				setTimeout(done, 1500)
			},
			onAfterEnter: () => (document.documentElement.style.overflow = ''),
		}
	} else if (
		from.name === 'publicaties-slug' &&
		(fromIsSlideshow || fromIsQuiz)
	) {
		to.meta.layoutTransition = {
			name: 'blur-transition',
			onEnter: (el, done) => setTimeout(done, 1500),
		}
		from.meta.layoutTransition = {
			name: fromIsQuiz ? 'quiz-transition' : 'slideshow-transition',
			onLeave: (el, done) => {
				document.documentElement.style.overflow = 'hidden'
				setTimeout(done, 1500)
			},
			onAfterLeave: () => (document.documentElement.style.overflow = ''),
		}
	} else if (
		to.name === 'themas-slug' &&
		from.name === 'themas-slug-publicaties'
	) {
		to.meta.layoutTransition = {
			name: 'slide-down',
			onAfterEnter: () => {
				if (to.meta.layoutTransition)
					to.meta.layoutTransition = { name: undefined }
			},
		}
		from.meta.layoutTransition = {
			name: 'slide-down',
			onAfterEnter: () => {
				if (from.meta.layoutTransition)
					from.meta.layoutTransition = { name: undefined }
			},
		}
	} else if (
		to.name === 'themas-slug-publicaties' &&
		from.name === 'themas-slug'
	) {
		to.meta.layoutTransition = {
			name: 'slide-up',
			onAfterEnter: () => {
				if (to.meta.layoutTransition)
					to.meta.layoutTransition = { name: undefined }
			},
		}
		from.meta.layoutTransition = {
			name: 'slide-up',
			onAfterEnter: () => {
				if (from.meta.layoutTransition)
					from.meta.layoutTransition = { name: undefined }
			},
		}
	}
})
