import type { OperationVariables, TypedDocumentNode } from '@apollo/client'
import type { VariablesParameter } from '@vue/apollo-composable/dist/useQuery'
import type { DocumentNode } from 'graphql'

export default function <ReturnType>({
	key,
	tags,
	query,
	variables,
	maxAge = 3600,
}: {
	key: string
	tags?: string[]
	query: DocumentNode | TypedDocumentNode<ReturnType, OperationVariables>
	variables?: VariablesParameter<OperationVariables>
	maxAge?: number
}) {
	const nuxtApp = useNuxtApp()

	const params = useRoute().query
	const token = params['token']?.toString()
	const livePreviewToken = params['x-craft-live-preview']?.toString()
	const previewToken = params['x-craft-preview']?.toString()

	let url = '/graphql'
	if (token && livePreviewToken)
		url = `${url}?x-craft-live-preview=${livePreviewToken}&token=${token}`
	else if (token && previewToken)
		url = `${url}?x-craft-preview=${previewToken}&token=${token}`

	return useFetch(url, {
		method: 'POST',
		body: {
			key,
			query,
			variables: variables ?? {},
			tags: tags ? [key, ...tags] : [key],
			maxAge,
		},
		key,
		dedupe: 'defer',

		// Client-side caching, so we only request the data once
		getCachedData: (key) => {
			// Somehow the default doesn't work...
			//@ts-ignore
			if (nuxtApp.payload.static && nuxtApp.payload.static[key]) {
				//@ts-ignore
				return nuxtApp.payload.static[key]
			}
			return nuxtApp.payload.data[key]
		},
	})
}
