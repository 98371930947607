<script setup lang="ts">
import type { SaveFeedbackSubmissionMutation } from '~/generated/operations'
import { SaveFeedbackSubmissionDocument } from '~/generated/operations'
const emit = defineEmits(['close'])
const email = ref('')
const description = ref('')
const submitted = ref(false)

const submitFeedback = () => {
	const { mutate: send, onDone } =
		useMutation<SaveFeedbackSubmissionMutation>(
			SaveFeedbackSubmissionDocument,
		)
	send({
		description: description.value,
		email: email.value,
		page: `${window.location.origin}${window.location.pathname}`,
	})

	onDone(() => {
		description.value = ''
		email.value = ''
		submitted.value = true
	})
}
</script>
<template>
	<div
		class="flex flex-col justify-center items-center absolute top-0 left-0 w-full h-screen z-50 pointer-events-none"
	>
		<div class="form">
			<CloseButton
				class="absolute top-4 right-4"
				color="gray"
				@click="$emit('close')"
			/>
			<h3 class="form__title">
				Stuur ons een reactie, vraag of suggestie
			</h3>

			<div v-if="submitted">
				<p class="text-body">
					Hartelijk dank voor reactie, vraag of suggestie. Als je je
					e-mailadres hebt achtergelaten komen we z.s.m. bij je terug
					met een reactie.
				</p>
			</div>
			<div class="form__fields" v-else>
				<div>
					<label for="description" class="form__label"
						>Omschrijving</label
					>
					<textarea
						id="description"
						v-model="description"
						class="form__field form__field--textarea"
					></textarea>
				</div>
				<div>
					<label for="email" class="form__label"
						>E-mailadres (optioneel)</label
					>
					<input
						id="email"
						v-model="email"
						class="form__field placeholder:text-black/30"
						placeholder="Vul je e-mailadres in"
					/>
				</div>
				<div class="flex justify-end">
					<Button type="button" @click="submitFeedback"
						>Versturen</Button
					>
				</div>
			</div>
		</div>
	</div>
</template>

<style scoped>
.form {
	@apply relative w-[90%] md:w-[650px] p-6 md:p-10 bg-white m-4 rounded-xl min-h-[400px] pointer-events-auto;
}
.form__title {
	@apply text-[30px] md:text-[40px] font-semibold tracking-tight leading-none w-3/4 mb-6;
}
.form__fields {
	@apply flex flex-col gap-6;
}
.form__field {
	@apply p-4 rounded-lg border-black/20 block w-full focus:ring-nemo-green;
}
.form__field--textarea {
	@apply h-[150px];
}
.form__label {
	@apply block pb-1 text-[14px];
}
</style>
