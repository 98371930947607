import revive_payload_client_4sVQNw7RlN from "/opt/APPS/nemo_kennislink/dev/www/source/frontend/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/opt/APPS/nemo_kennislink/dev/www/source/frontend/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/opt/APPS/nemo_kennislink/dev/www/source/frontend/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/opt/APPS/nemo_kennislink/dev/www/source/frontend/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/opt/APPS/nemo_kennislink/dev/www/source/frontend/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_A0OWXRrUgq from "/opt/APPS/nemo_kennislink/dev/www/source/frontend/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/opt/APPS/nemo_kennislink/dev/www/source/frontend/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/opt/APPS/nemo_kennislink/dev/www/source/frontend/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_5drvKYmdqw from "/opt/APPS/nemo_kennislink/dev/www/source/frontend/node_modules/@dargmuesli/nuxt-cookie-control/dist/runtime/plugin.mjs";
import plugin_t2GMTTFnMT from "/opt/APPS/nemo_kennislink/dev/www/source/frontend/node_modules/@nuxtjs/device/dist/runtime/plugin.mjs";
import plugin_eTVJQYlCmx from "/opt/APPS/nemo_kennislink/dev/www/source/frontend/node_modules/@nuxtjs/apollo/dist/runtime/plugin.mjs";
import chunk_reload_client_UciE0i6zes from "/opt/APPS/nemo_kennislink/dev/www/source/frontend/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import use_scroll_GlzOjqWPPr from "/opt/APPS/nemo_kennislink/dev/www/source/frontend/plugins/use-scroll.ts";
import Vue3Marquee_client_r0z0zv1iLW from "/opt/APPS/nemo_kennislink/dev/www/source/frontend/plugins/Vue3Marquee.client.ts";
import apollo_1ILFkzpYSr from "/opt/APPS/nemo_kennislink/dev/www/source/frontend/plugins/apollo.ts";
import bin_packing_AIG7k7FaAD from "/opt/APPS/nemo_kennislink/dev/www/source/frontend/plugins/bin-packing.ts";
import gtm_cXsUZobmSF from "/opt/APPS/nemo_kennislink/dev/www/source/frontend/plugins/gtm.ts";
import hooks_oojiaYScZq from "/opt/APPS/nemo_kennislink/dev/www/source/frontend/plugins/hooks.ts";
import logo_toggler_kH0TyRALZG from "/opt/APPS/nemo_kennislink/dev/www/source/frontend/plugins/logo-toggler.ts";
import motion_N0nXvikqD4 from "/opt/APPS/nemo_kennislink/dev/www/source/frontend/plugins/motion.ts";
import tooltip_Di9mlKVbnI from "/opt/APPS/nemo_kennislink/dev/www/source/frontend/plugins/tooltip.ts";
import plugin_client_QHzhVBB2Ll from "/opt/APPS/nemo_kennislink/dev/www/source/frontend/.nuxt/nuxt-booster/plugin.client.js";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_5drvKYmdqw,
  plugin_t2GMTTFnMT,
  plugin_eTVJQYlCmx,
  chunk_reload_client_UciE0i6zes,
  use_scroll_GlzOjqWPPr,
  Vue3Marquee_client_r0z0zv1iLW,
  apollo_1ILFkzpYSr,
  bin_packing_AIG7k7FaAD,
  gtm_cXsUZobmSF,
  hooks_oojiaYScZq,
  logo_toggler_kH0TyRALZG,
  motion_N0nXvikqD4,
  tooltip_Di9mlKVbnI,
  plugin_client_QHzhVBB2Ll
]