import type { ModuleOptions } from '#cookie-control/types'

export default {
  "barPosition": "bottom-full",
  "closeModalOnClickOutside": true,
  "colors": {
    "barBackground": "#000",
    "barButtonBackground": "#fff",
    "barButtonColor": "#000",
    "barButtonHoverBackground": "#333",
    "barButtonHoverColor": "#fff",
    "barTextColor": "#fff",
    "checkboxActiveBackground": "#000",
    "checkboxActiveCircleBackground": "#fff",
    "checkboxDisabledBackground": "#ddd",
    "checkboxDisabledCircleBackground": "#fff",
    "checkboxInactiveBackground": "#000",
    "checkboxInactiveCircleBackground": "#fff",
    "controlButtonBackground": "#fff",
    "controlButtonHoverBackground": "#000",
    "controlButtonIconColor": "#000",
    "controlButtonIconHoverColor": "#fff",
    "focusRingColor": "#808080",
    "modalBackground": "#fff",
    "modalButtonBackground": "#000",
    "modalButtonColor": "#fff",
    "modalButtonHoverBackground": "#333",
    "modalButtonHoverColor": "#fff",
    "modalOverlay": "#000",
    "modalOverlayOpacity": 0.8,
    "modalTextColor": "#000",
    "modalUnsavedColor": "#fff"
  },
  "cookies": {
    "necessary": [
      {
        "description": {
          "en": "These cookies keeps track of certain states",
          "nl": "Deze cookies houden bij of je bijvoorbeeld deze popup al gezien hebt."
        },
        "id": "funct",
        "name": {
          "en": "Functional cookies",
          "nl": "Functionele cookies"
        },
        "targetCookieIds": [
          "newsletter_popup_shown",
          "ncc_e",
          "ncc_c"
        ]
      }
    ],
    "optional": [
      {
        "description": {
          "en": "This cookie gathers statistics.",
          "nl": "Voor het verzamelen van statistieken."
        },
        "id": "ga",
        "name": {
          "en": "Google Analytics",
          "nl": "Google Analytics"
        },
        "targetCookieIds": [
          "_ga",
          "_ga_KTJ54SWL78"
        ]
      }
    ]
  },
  "cookieExpiryOffsetMs": 31536000000,
  "cookieNameIsConsentGiven": "ncc_c",
  "cookieNameCookiesEnabledIds": "ncc_e",
  "cookieOptions": {
    "path": "/"
  },
  "isAcceptNecessaryButtonEnabled": true,
  "isControlButtonEnabled": true,
  "isCookieIdVisible": false,
  "isCssEnabled": true,
  "isCssPonyfillEnabled": false,
  "isDashInDescriptionEnabled": true,
  "isIframeBlocked": false,
  "isModalForced": false,
  "locales": [
    "nl",
    "en"
  ],
  "localeTexts": {
    "en": {
      "accept": "Accept",
      "acceptAll": "Accept all",
      "bannerDescription": "We use our own cookies and third-party cookies so that we can display this website correctly and better understand how this website is used, with a view to improving the services we offer. A decision on cookie usage permissions can be changed anytime using the cookie button that will appear after a selection has been made on this banner.",
      "bannerTitle": "Cookies",
      "close": "Close",
      "cookiesFunctional": "Functional cookies",
      "cookiesNecessary": "Necessary cookies",
      "cookiesOptional": "Optional cookies",
      "decline": "Decline",
      "declineAll": "Decline all",
      "here": "here",
      "iframeBlocked": "To see this, please enable functional cookies",
      "manageCookies": "Learn more and customize",
      "save": "Save",
      "settingsUnsaved": "You have unsaved settings"
    },
    "nl": {
      "accept": "Cookies toestaan",
      "acceptAll": "Cookies toestaan",
      "bannerDescription": "Lees hier meer of pas je cookievoorkeuren aan.",
      "bannerTitle": "De website van NEMO Kennislink maakt gebruik van cookies.",
      "close": "Sluiten",
      "cookiesFunctional": "Functionele cookies",
      "cookiesNecessary": "Noodzakelijke cookies",
      "cookiesOptional": "Optionele cookies",
      "decline": "Niet toestaan",
      "declineAll": "Niet toestaan",
      "here": "hier",
      "iframeBlocked": "Om dit te kunnen bekijken dienen functionele cookies geaccepteerd te worden",
      "manageCookies": "Beheer cookies",
      "save": "Opslaan",
      "settingsUnsaved": "Er zijn niet-opgeslagen instellingen"
    }
  }
} as ModuleOptions