<script setup lang="ts">
// Initialize global state here
// From https://github.com/vuejs/pinia/discussions/947
import { useNavigationStore } from './store/navigation'
import { useSettingsStore } from './store/settings'
import { useTextsStore } from './store/texts'

const navigationStore = useNavigationStore()
await navigationStore.fetch()
const settingsStore = useSettingsStore()
await settingsStore.fetchData()
const textsStore = useTextsStore()
await textsStore.fetch()

useHead({
	htmlAttrs: {
		lang: 'nl',
	},
	titleTemplate: (titlePart) => {
		const siteName = 'NEMO Kennislink'
		// TO DO: get this from somewhere
		return titlePart ? `${titlePart} - ${siteName}` : siteName
	},
	meta: [
		{
			name: 'apple-mobile-web-app-capable',
			content: 'yes',
		},
		{
			name: 'apple-mobile-web-app-status-bar-style',
			content: 'black-translucent',
		},
	],
	link: [
		{
			rel: 'apple-touch-icon',
			type: 'image/png',
			href: '/apple-touch-icon.png',
		},
	],
})

useNuxtApp().hook('page:loading:end', () => {
	const links = document.querySelectorAll('a')
	links.forEach((link) => {
		if (link.hostname !== location.hostname && link.target === '') {
			link.target = '_blank'
		}
	})
})
</script>

<template>
	<div class="antialiased font-sans relative">
		<NuxtLayout>
			<NuxtPage />
		</NuxtLayout>

		<CookieControl
			v-if="useRuntimeConfig().public.environment === 'production'"
			locale="nl"
		/>
	</div>
</template>

<style>
@import '~/assets/css/main.css';
@import '~/assets/css/transitions.css';
@import '~/assets/css/reactive-cursor.postcss';
</style>
