<template>
	<div
		class="bg-white rounded-full shadow-[inset_0_0px_0px_5px_rgb(0,150,250)]"
	>
		<img
			src="@/assets/icons/kamagurka_icon.svg?inline"
			alt="Kamagurka icoon"
		/>
	</div>
</template>
